import React, { Component } from 'react';
import profile from './img/profile.jpg';
import './App.css';
import { FaLinkedin, FaFacebook, FaEnvelope, FaHashtag, FaGithub, FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import ReactCardFlip from 'react-card-flip';

class App extends Component {
  constructor() {
    super();
    this.state = {
      isFlipped: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    console.log('herer');
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={profile} className="App-logo" alt="logo" />
          <p>
            ZAW HLAING BWAR <code>(Full-stack developer)</code>
          </p>
        </header>
        <div className="iconContainer">
          <a className="icon-anchor" target='_blank' rel="noopener noreferrer" href={"https://www.linkedin.com/in/zawhlaingbwar/"}>
            <FaLinkedin size={40} className="icon lkicon"/>
          </a>
          <a className="icon-anchor" target='_blank' rel="noopener noreferrer" href={"https://www.facebook.com/zawhlaingbwarr/"}>
            <FaFacebook size={40} className="icon fbicon"/>
          </a>
          <a className="icon-anchor" target='_blank' rel="noopener noreferrer" href={"https://github.com/zaw-hlaing-bwar"}>
            <FaGithub size={40} className="icon ghicon"/>
          </a>
          <a className="icon-anchor" target='_blank' rel="noopener noreferrer" href={"mailto:zawhlaingbwarr@gmail.com"}>
            <FaEnvelope size={40} className="icon gmicon"/>
          </a>
        </div>
        <div className="summary">
          <ReactCardFlip isFlipped={this.state.isFlipped}>
          <div key="front">
          <h2>Summary</h2>
            I am a highly passionate developer leading for full stacks.
            I have strong logical thinking and problem solving skill come along with innovative business ideas.
            I also understand both object oriented programming and functional programming.
            I am a developer with both frontend, backend, core application and mobile experiences.
            I can create and consume both rest and graphql endpoints.
            I am a fast learner and always willing to learn new technologies.
          </div>
          <div key="back">
          <h2>Other side of me</h2>
            On the other side of me, I can be said like friendly most of the time.
            You might think me a little weird before we know, but I am sure you will find me friendly afterwards.
            I spend most of my time reading and writing.
            Yes, I write poems and novels.
            You can ask one from me personally if you would like to try.
            Sometime, I play guitar and sing although my voice is not good that much.
            I like to watch interesting movies and I can invest my sleeping hours for that.
            I love freedom and I am a fan of Christopher McCandless(Into the wild).
            You can connect me on one of the social media even if you want to talk with me for work non-related things.
          </div>
          </ReactCardFlip>
        </div>
        <div className="buttonContainer">
          <FaArrowCircleLeft size={30} onClick={this.state.isFlipped && this.handleClick} className="buttom-gp"/>
          <FaArrowCircleRight size={30} onClick={!this.state.isFlipped && this.handleClick} className="buttom-gp"/>
        </div>
        <div className="skills">
        <h2>Technology skills</h2>
          <div className="skill"><FaHashtag size={15}/> Node.js</div>
          <div className="skill"><FaHashtag size={15}/> Reactjs</div>
          <div className="skill"><FaHashtag size={15}/> Nextjs</div>
          <div className="skill"><FaHashtag size={15}/> React Native</div>
          <div className="skill"><FaHashtag size={15}/> Electronjs</div>
          <div className="skill"><FaHashtag size={15}/> Meteor</div>
          <div className="skill"><FaHashtag size={15}/> Graphql</div>
          <div className="skill"><FaHashtag size={15}/> Ruby</div>
          <div className="skill"><FaHashtag size={15}/> Blockchain</div>
          <div className="skill"><FaHashtag size={15}/> MultiChain</div>
          <div className="skill"><FaHashtag size={15}/> PHP</div>
          <div className="skill"><FaHashtag size={15}/> MySQL</div>
          <div className="skill"><FaHashtag size={15}/> PostgreSQL</div>
          <div className="skill"><FaHashtag size={15}/> MongoDB</div>
          <div className="skill"><FaHashtag size={15}/> Redis</div>
          <div className="skill"><FaHashtag size={15}/> Laravel</div>
          <div className="skill"><FaHashtag size={15}/> Ruby on Rails</div>
          <div className="skill"><FaHashtag size={15}/> CodeIgniter</div>
          <div className="skill"><FaHashtag size={15}/> EC-CUBE</div>
          <div className="skill"><FaHashtag size={15}/> Symfony</div>
          <div className="skill"><FaHashtag size={15}/> Project Management</div>
          <div className="skill"><FaHashtag size={15}/> Rest API</div>
        </div>
      </div>
    );
  }
}

export default App;
